import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import AdvanceSearch from './advanceSearch';
import viewInvoiceEntry from './viewInvoiceEntry';
import party from '@/app/components/generalLedger/party';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
export default {
  name: 'invoiceEntry',
  components: {
    AdvanceSearch,
    viewInvoiceEntry,
    party,
    ModulesList,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication
  },
  watch: {
    currentPage: function() {
      this.payload._page = this.currentPage - 1;
      this.payload._limit = this.perPage;
      this.getInvoicesList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.payload._page = this.currentPage - 1;
      this.payload._limit = this.perPage;
      this.getInvoicesList();
    }
  },
  data() {
    return {
      searchParameter: null,
      loader: false,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      isAdvanceSearchEnable: false,
      showViewTransaction: false,
      showValueSetModal: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      gridData: [],
      party: {
        name: null,
        id: null
      },
      partySite: {
        name: null,
        id: null
      },
      customer: {
        name: null,
        id: null
      },
      customerSite: {
        name: null,
        id: null
      },
      invoice_num: '',
      batch_number: '',
      gridFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'batch_number'
        },
        {
          key: 'module_name'
        },
        {
          key: 'prj_name',
          label: 'Lease Project'
        },
        {
          key: 'invoice_num',
          label: 'AP Invoice No.'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_type',
          label: 'Trx Type'
        },
        {
          key: 'invoice_status_meaning',
          label: 'Invoice Status'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'value_date'
        },
        {
          key: 'due_date'
        },
        {
          key: 'party_type'
        },
        {
          key: 'party_name'
        },
        {
          key: 'party_site_id'
        },
        {
          key: 'party_site'
        },
        {
          key: 'party_site_address'
        },
        {
          key: 'currency'
        },
        {
          key: 'exchange_rate'
        },
        {
          key: 'exchange_rate_type',
          label: 'Exch Rate Type'
        },
        {
          key: 'exchange_date'
        },
        {
          key: 'base_amount',
          label: 'Total Amount'
        },
        {
          key: 'tds_deducted',
          label: 'TDS Deducted'
        },
        {
          key: 'amount_paid'
        },
        {
          key: 'irn',
          label: 'IRN'
        },
        {
          key: 'fp_gst_number',
          label: 'FP GST Number'
        },
        {
          key: 'tp_gst_number',
          label: 'TP GST No.'
        },
        {
          key: 'document_num',
          label: 'Document No.'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'invoice_line_total'
        },
        {
          key: 'liability_ccid_dlt',
          label: 'Liability CCID'
        },
        {
          key: 'cancelled_date'
        },
        {
          key: 'cancelled_by'
        },
        {
          key: 'supplier_tax_invoice'
        },
        {
          key: 'supplier_tax_invoice_date'
        },
        {
          key: 'supplier_tax_invoice_rate'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'internal_contact_email'
        },
        {
          key: 'remit_to_party_name'
        },
        {
          key: 'remit_to_party_site'
        },
        {
          key: 'po_matched_flag'
        },
        {
          key: 'request_num'
        },
        {
          key: 'cancelled_by_name'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_updated_by'
        },
        {
          key: 'last_updated_date'
        }
      ],
      apInvoiceId: null,
      showPartyModal: false,
      showCustomerSiteModal: false,
      showExcelUploadModal:false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      selectedModule: {
        name: null,
        id: null
      },
      selectedInvoiceDetails: null,
      showHideModule: false,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      selectedGridData: null,
      partyType: null,
      setPartyTimeout: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showViewTransaction &&
          !this.isAdvanceSearchEnable
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'payable/getInvoiceEntryList',
            'invoice-entry',
            () => (this.loader = false)
          );
        }
        if(actionName === 'upload'){
          this.showExcelUploadModal = true;
        }
      }
    });
  },
  methods: {
    showHideExcelUpload(flag) {
      this.showExcelUploadModal = flag;
    },
    getInvoicesList() {
      if (this.searchParams) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage
        };
        Object.assign(this.payload, this.searchParams);
      } else {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          module_id: this.selectedModule.id,
          party_id: this.party.id,
          party_site_id: this.partySite.id,
          party_type: this.partyType,
          invoice_num: this.invoice_num,
          batch_number: this.batch_number
        };
      }
      this.loader = true;
      this.$store
        .dispatch('payable/getInvoiceEntryList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    clearSearch() {
      this.party = {
        name: null,
        id: null
      };
      this.partySite = {
        name: null,
        id: null
      };
      this.invoice_num = '';
      this.batch_number = '';
      this.partyType = null;
      this.selectedModule = {
        name: null,
        id: null
      };
      this.totalRows = null;
      this.gridData = [];
    },
    advanceSearchModal() {
      this.isAdvanceSearchEnable = true;
    },
    searchBill(payload) {
      if (payload) {
        this.searchParameter = payload;
      } else {
        this.searchParameter = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          },
          with_unit: true
        };
      }
      this.loader = true;
      this.isAdvanceSearchEnable = false;
      this.$store
        .dispatch('fms/getFMSTransaction', this.searchParameter)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(items) {
      this.apInvoiceId = items.ap_invoice_id;
      this.showViewTransaction = true;
    },
    showHidePartyModal(flag) {
      if (this.partyType) {
        this.showPartyModal = flag;
        if (flag) {
          if (this.partyType === 'VENDOR') {
            this.setPartyTimeout = setTimeout(() => {
              this.eventBus.$emit('userType', 'VEND');
            }, 0);
          } else if (this.partyType === 'CUSTOMER') {
            this.setPartyTimeout = setTimeout(() => {
              this.eventBus.$emit('userType', 'CUST');
            }, 0);
          }
        }
      } else {
        alert('Please select party type!');
      }
    },
    getSelectedPartyCustomer(item) {
      this.party.name = item.party_name;
      this.showPartyModal = false;
      this.partySite = {
        id: null,
        name: null
      };
      if (this.partyType === 'VENDOR') {
        this.getVendorIdByPartyId(item.party_id);
      } else if (this.partyType === 'CUSTOMER') {
        this.getCustomerByPartyId(item.party_id);
      }
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.id = response.data.data;
            this.getVendorSites();
          }
        });
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.party.id && this.partyType) {
        this.showCustomerSiteModal = flag;
      } else if (!this.partyType) {
        alert('Please select party type!');
      } else if (!this.party.id) {
        alert('Please select party!');
      }
    },
    getVendorSites() {
      this.gridDataSite = [];
      this.$store
        .dispatch('leaseSchedule/getVendorSiteByVendorId', this.party.id)
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customerId: this.party.id
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.partySite.name = item.site_name;
      if (this.partyType === 'VENDOR') {
        this.partySite.id = item.vendor_site_id;
      } else if (this.partyType === 'CUSTOMER') {
        this.partySite.id = item.customer_site_id;
      }
      this.showCustomerSiteModal = false;
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showHideModule = false;
    },
    selectedInvoice(item) {
      this.selectedInvoiceDetails = Object.assign({}, item);
      this.showHistoryBtn = true;
      this.apInvoiceId = item.ap_invoice_id;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedInvoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedInvoiceDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    openValueSetModal(vsetCode = appStrings.VALUESETTYPE.INV_BATCH_NAME) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME) {
        this.batch_number = item.value_code;
      } else {
        this.partyType = item.value_code;
        this.party = {
          name: null,
          id: null
        };
        this.partySite = {
          name: null,
          id: null
        };
      }
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    mainSearch() {
      if (
        this.selectedModule.id ||
        this.partyType ||
        this.party.name ||
        this.partySite.name ||
        this.batch_number ||
        this.invoice_num
      ) {
        this.searchParams = null;
        this.getInvoicesList();
      } else {
        alert('Please select atleast one filter');
      }
    },
    advanceSearch(payload) {
      this.isAdvanceSearchEnable = false;
      this.searchParams = payload;
      this.getInvoicesList();
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.partyType) {
        this.partyType = null;
      } else if (vsetCode === this.batch_number) {
        this.batch_number = null;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setPartyTimeout);
    this.unsubscribe();
    this.$store.dispatch('payable/saveAdvSearchFilters', null);
  }
};

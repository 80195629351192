import { ModelSelect, BasicSelect } from 'vue-search-select';
import store from '../../../../../../store';
import Datepicker from 'vuejs-datepicker';
import Currency from '../../../../admin/currency';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
export default {
  name: 'advanceSearch',
  components: {
    ModelSelect,
    BasicSelect,
    Datepicker,
    Currency,
    DatePicker,
    party,
    ModulesList
  },
  watch: {
    selectedLeagalEntity: function() {
      if (this.selectedLeagalEntity.value) {
        this.getCustomer(this.selectedLeagalEntity.value);
      }
    },
    selectedBillRequest: function() {
      if (this.selectedBillRequest.value) {
        this.getFmsBillTemplate(this.selectedBillRequest.value);
      }
    }
  },
  data() {
    return {
      customerCurrentPage: 1,
      showValueSetModal: false,
      parent_value_set_id: null,
      showCustomerModal: false,
      customerTotalRows: null,
      customerName: null,
      customerPerPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      openCurrencyModal: false,
      defaultValue: {
        text: null,
        value: null
      },
      defaultSelect: {
        value: null,
        text: null
      },
      defaultList: [],
      loader: false,
      lovFieldName: '',
      lovId: '',
      keyValue: {
        value: null,
        text: null
      },
      advanceSearchForm: {
        leagalEntity: [
          {
            value: null,
            text: '--select--'
          }
        ],
        orgUnit: [],
        receiptAgainst: [
          {
            value: null,
            text: null
          }
        ],
        billingStatusList: [],
        paymentMode: [
          {
            value: null,
            text: null
          }
        ],
        drawnOn: [
          {
            value: null,
            text: null
          }
        ],

        accountNumList: [
          {
            value: null,
            text: null
          }
        ],
        sourceList: []
      },
      documentNumber: '',
      batch_number: '',
      invoiceStatus: null,
      selectedReceiptAgainst: {
        value: null,
        text: null
      },
      selectedBillingStatus: {
        value: null,
        text: null
      },
      selectedLeagalEntity: {
        value: null,
        text: null
      },
      leasePrj: {
        text: null,
        value: null
      },
      recoveryType: {
        value: null,
        text: null
      },
      scheduleType: {
        value: null,
        text: null
      },
      selectedOrgUnit: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      receiptNum: '',
      selectedDrawnOn: {
        value: null,
        text: null
      },

      selectedSource: {
        value: null,
        text: null
      },
      billingCycleList: [],
      selectedBillingCycle: {
        value: null,
        text: null
      },
      billRequestList: [],
      selectedBillRequest: {
        value: null,
        text: null
      },
      billTemplateList: [],
      selectedBillTemplate: {
        value: null,
        text: null
      },
      selectedCustomer: {
        name: null,
        customerId: null,
        siteName: null,
        siteId: null
      },
      currency: {
        name: '',
        id: null
      },
      bpDate: null,
      dueDate: null,
      glDate: null,
      billDate: null,
      amountFrom: null,
      amountTo: null,
      customerList: [],
      customerFields: [
        {
          key: 'site_name'
        },
        {
          key: 'customer_site_id',
          class: 'd-none'
        },
        {
          key: 'customer_id',
          class: 'd-none'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'complete_address'
        },
        {
          key: 'address_id',
          class: 'd-none'
        }
      ],
      address: null,
      invoiceDate: null,
      party: {
        name: null,
        id: null
      },
      partySite: {
        name: null,
        id: null
      },
      showPartyModal: null,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      selectedModule: {
        id: null,
        name: null
      },
      showHideModule: false,
      requestNum: null,
      partyType: null,
      setPartyTimeout: null,
      depVsetParam: null,
      trxType: {
        text: null,
        value: null
      },
      payStatus: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    const advFormData = this.$store.state.payable.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    } else {
      this.getReceiptAgainast();
      // this.getLegalEntity();
      this.getBillingStatus();
      this.getBillingCycle();
      this.getFmsBillRequest();
      this.eventBus.$on('getCurrency', getCurrency => {
        this.currency.name = getCurrency.currency;
        this.currency.id = getCurrency.currency_id;
        this.openCurrencyModal = false;
      });
    }
  },
  methods: {
    searchData() {
      if (
        this.selectedModule.id ||
        this.selectedLeagalEntity.value ||
        this.selectedOrgUnit.value ||
        this.leasePrj.value ||
        this.partyType ||
        this.party.id ||
        this.partySite.id ||
        this.trxType.value ||
        this.documentNumber ||
        this.batch_number ||
        this.invoiceStatus ||
        this.currency.id ||
        this.requestNum ||
        this.amountFrom ||
        this.amountTo ||
        this.glDate ||
        this.payStatus.value
      ) {
        this.searchArTrx();
      } else {
        alert('Please select atleast one filter');
      }
    },
    searchArTrx() {
      const payload = {
        le_id: this.selectedLeagalEntity.value,
        org_ou_id: this.selectedOrgUnit.value,
        party_id: this.party.id,
        party_site_id: this.partySite.id,
        doc_num: this.documentNumber,
        currency_id: this.currency.id,
        req_num: this.requestNum,
        invoice_amount_from: this.amountFrom,
        invoice_amount_to: this.amountTo,
        gl_date_from: this.glDate && commonHelper.formattedDate(this.glDate[0]),
        gl_date_to: this.glDate && commonHelper.formattedDate(this.glDate[1]),
        value_date_from:
          this.dueDate && commonHelper.formattedDate(this.dueDate[0]),
        value_date_to:
          this.dueDate && commonHelper.formattedDate(this.dueDate[1]),
        invoice_date_from:
          this.invoiceDate && commonHelper.formattedDate(this.invoiceDate[0]),
        invoice_date_to:
          this.invoiceDate && commonHelper.formattedDate(this.invoiceDate[1]),
        module_id: this.selectedModule.id,
        batch_number: this.batch_number,
        invoice_status_vset: this.invoiceStatus,
        party_type: this.partyType,
        trx_type_dtl_id: this.trxType.value,
        entity_id: this.leasePrj.value,
        paid: this.payStatus.value
      };
      this.$emit('advanceSearch', payload);
      this.$store.dispatch('payable/saveAdvSearchFilters', this);
    },
    getBillingCycle() {
      this.$store.dispatch('fms/getBillingCycle').then(response => {
        if (response.status === 200) {
          const result = response.data.data;
          const valueTypeSet = result.map(type => {
            return {
              value: type.billing_cycle_hdr_id,
              text: type.billing_cycle_name
            };
          });
          this.billingCycleList = valueTypeSet;
          this.selectedBillingCycle =
            valueTypeSet && valueTypeSet.length === 1
              ? valueTypeSet
              : this.billingCycleList;
        }
      });
    },
    getFmsBillRequest() {
      const payload = {
        menu_id: store.state.shared.menuId
      };
      this.$store
        .dispatch('fms/getFmsBillRequest', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const valueTypeSet = result.map(type => {
              return {
                value: type.request_id,
                text: type.request_name
              };
            });
            this.billRequestList = valueTypeSet;
          }
        })
        .catch(() => {});
    },
    getFmsBillTemplate(requestId) {
      const payload = {
        request_id: requestId
      };
      this.$store
        .dispatch('fms/getFMSBillingTemplate', payload)
        .then(response => {
          if (response.status === 200) {
            const result = response.data.data;
            const valueTypeSet = result.map(type => {
              return {
                value: type.template_id,
                text: type.template_name
              };
            });
            this.billTemplateList = valueTypeSet;
          }
        });
    },
    // getOrganizationList(leId) {
    //   this.loader = true;
    //   try {
    //     const queryParams = {
    //       _page: 0,
    //       _limit: this.perPage,
    //       org_type: 'OU',
    //       user_id: store.state.auth.userId,
    //       responsibility_id: store.state.shared.responsibilityId,
    //       org_parent_id: leId
    //     };
    //     this.$store
    //       .dispatch('organization/getOrganizationList', queryParams)
    //       .then(response => {
    //         this.loader = false;
    //         if (response.data.data.page) {
    //           const result = response.data.data.page;
    //           const organization = result.map(type => {
    //             return {
    //               value: type.org_id,
    //               text: type.org_name
    //             };
    //           });
    //           this.advanceSearchForm.orgUnit = organization;
    //           this.selectedOrgUnit =
    //             organization && organization.length
    //               ? organization[0]
    //               : this.selectedOrgUnit;
    //         }
    //       });
    //   } catch (err) {
    //     this.err = err;
    //   }
    // },
    getReceiptAgainast() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'FMS_SEARCH_BY')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.receiptAgainst = getValueAndText;
            this.selectedReceiptAgainst =
              getValueAndText && getValueAndText.length
                ? getValueAndText[0]
                : this.selectedReceiptAgainst;
          }
        });
    },
    getBillingStatus() {
      this.$store
        .dispatch('receipt/getLOVBySetType', 'BILL_STATUS')
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.advanceSearchForm.billingStatusList = getValueAndText;
          }
        });
    },
    // getLegalEntity() {
    //   const payload = store.state.shared.responsibilityId;
    //   this.$store
    //     .dispatch('receipt/getOrganizationLov', payload)
    //     .then(response => {
    //       if (response.status === 200) {
    //         const results = response.data.data;
    //         const leagalEntity = results.map(type => {
    //           return {
    //             value: type.org_id,
    //             text: type.org_name
    //           };
    //         });
    //         this.advanceSearchForm.leagalEntity = leagalEntity;
    //         this.selectedLeagalEntity =
    //           leagalEntity && leagalEntity.length
    //             ? leagalEntity[0]
    //             : this.selectedLeagalEntity;
    //         this.getOrganizationList(this.selectedLeagalEntity.value);
    //         this.getCustomer(this.selectedLeagalEntity.value);
    //       }
    //     });
    // },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getCustomer(leId) {
      const payload = {
        _page: this.customerCurrentPage - 1,
        _limit: this.customerPerPage,
        le_id: leId ? leId : null,
        customer_name: this.customerName
      };
      this.loader = true;
      this.$store.dispatch('fms/getCustomerDetail', payload).then(response => {
        if (response.status === 200) {
          this.loader = false;
          const result = response.data.data;
          if (result.page && result.page.length > 0) {
            this.customerList = result.page;
            this.customerTotalRows = result.total_page;
          }
        }
      });
    },
    selectedCustomerRow(item) {
      this.selectedCustomer.name = item.customer_name;
      this.selectedCustomer.customerId = item.customer_id;
      this.selectedCustomer.siteName = item.site_name;
      this.selectedCustomer.siteId = item.customer_site_id;
      this.showCustomerModal = false;
    },
    openCustomerModal(flag) {
      this.showCustomerModal = flag;
    },
    clearFilters() {
      // const defaultSelecteValue = {
      //   value: null,
      //   text: null
      // };
      // this.selectedBillRequest = defaultSelecteValue;
      // this.selectedCustomer.customerId = null;
      // this.selectedCustomer.siteId = null;
      // this.selectedCustomer.name = null;
      // this.selectedCustomer.siteName = null;
      //
      this.selectedLeagalEntity = {
        value: null,
        text: null
      };
      this.selectedOrgUnit = {
        value: null,
        text: null
      };
      this.selectedModule = {
        name: null,
        id: null
      };
      // this.selectedLeagalEntity = defaultSelecteValue;
      // this.selectedOrgUnit = defaultSelecteValue;
      // this.payStatus = defaultSelecteValue;
      this.vendor = {
        name: null,
        id: null
      };
      this.vendorSite = {
        name: null,
        id: null
      };
      this.selectedCustomer = {
        name: null,
        customerId: null,
        siteName: null,
        siteId: null
      };
      this.leasePrj = {
        value: null,
        text: null
      };
      this.documentNumber = null;
      this.invoiceStatus = null;
      this.currency = {
        name: null,
        id: null
      };
      this.amountFrom = null;
      this.amountTo = null;
      this.glDate = [];
      this.dueDate = [];
      this.invoiceDate = [];
      this.batch_number = '';
      this.requestNum = null;
      this.partyType = null;
      this.parent_value_set_id = null;
      this.trxType = {
        value: null,
        text: null
      };
      this.payStatus = {
        value: null,
        text: null
      };
      this.$store.dispatch('payable/saveAdvSearchFilters', null);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.selectedLeagalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.parent_value_set_id = this.selectedModule.id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.selectedLeagalEntity.value;
        this.depVsetParam = this.selectedOrgUnit.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLeagalEntity.text = item.value_code;
        this.selectedLeagalEntity.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.selectedOrgUnit.text = item.value_code;
        this.selectedOrgUnit.value = item.value_set_dtl_id;
        this.parent_value_set_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INV_BATCH_NAME) {
        this.batch_number = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_VALIDATION) {
        this.invoiceStatus = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PARTY_TYPE) {
        this.partyType = item.value_code;
        this.party = {
          name: null,
          id: null
        };
        this.partySite = {
          name: null,
          id: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.trxType = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.AP_PAYMENT_STATUS) {
        this.payStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    showHidePartyModal(flag) {
      if (this.partyType) {
        this.showPartyModal = flag;
        if (flag) {
          if (this.partyType === 'VENDOR') {
            this.setPartyTimeout = setTimeout(() => {
              this.eventBus.$emit('userType', 'VEND');
            }, 0);
          } else if (this.partyType === 'CUSTOMER') {
            this.setPartyTimeout = setTimeout(() => {
              this.eventBus.$emit('userType', 'CUST');
            }, 0);
          }
        }
      } else {
        alert('Please select party type!');
      }
    },
    getSelectedPartyCustomer(item) {
      this.party.name = item.party_name;
      this.showPartyModal = false;
      this.partySite = {
        id: null,
        name: null
      };
      if (this.partyType === 'VENDOR') {
        this.getVendorIdByPartyId(item.party_id);
      } else if (this.partyType === 'CUSTOMER') {
        this.getCustomerByPartyId(item.party_id);
      }
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.id = response.data.data;
            this.getVendorSites();
          }
        });
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.party.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    getVendorSites() {
      this.gridDataSite = [];
      this.$store
        .dispatch('leaseSchedule/getVendorSiteByVendorId', this.party.id)
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customerId: this.party.id
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.party.id && this.partyType) {
        this.showCustomerSiteModal = flag;
      } else if (!this.partyType) {
        alert('Please select party type!');
      } else if (!this.party.id) {
        alert('Please select party!');
      }
    },
    selectedSite(item) {
      this.partySite.name = item.site_name;
      if (this.partyType === 'VENDOR') {
        this.partySite.id = item.vendor_site_id;
      } else if (this.partyType === 'CUSTOMER') {
        this.partySite.id = item.customer_site_id;
      }
      this.showCustomerSiteModal = false;
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showHideModule = false;
      this.trxType = {
        value: null,
        text: null
      };
    },
    chooseValuset(field) {
      switch (field) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'operating_unit':
          this.openValueSetModal(appStrings.VALUESETTYPE.OU_LIST);
          break;
        case 'batch_number':
          this.openValueSetModal(appStrings.VALUESETTYPE.INV_BATCH_NAME);
          break;
        case 'invoice_status':
          this.openValueSetModal(appStrings.VALUESETTYPE.TRX_VALIDATION);
          break;
        case 'party_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PARTY_TYPE);
          break;
        case 'lease_prj':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
          break;
      }
    },
    setSearchItems(item) {
      this.selectedLeagalEntity = item.selectedLeagalEntity;
      this.selectedOrgUnit = item.selectedOrgUnit;
      this.party = item.party;
      this.partySite = item.partySite;
      this.documentNumber = item.documentNumber;
      this.currency = item.currency;
      this.requestNum = item.requestNum;
      this.amountFrom = item.amountFrom;
      this.amountTo = item.amountTo;
      this.glDate = item.glDate;
      this.dueDate = item.dueDate;
      this.invoiceDate = item.invoiceDate;
      this.selectedModule = item.selectedModule;
      this.batch_number = item.batch_number;
      this.invoiceStatus = item.invoiceStatus;
      this.partyType = item.partyType;
      this.trxType = item.trxType;
    },

    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.selectedLeagalEntity.value:
          this.selectedLeagalEntity = {
            value: null,
            text: null
          };
          break;
        case this.selectedOrgUnit.value:
          this.selectedOrgUnit = {
            value: null,
            text: null
          };
          break;
        case this.leasePrj.value:
          this.leasePrj = {
            value: null,
            text: null
          };
          break;
        case this.partyType:
          this.partyType = null;
          break;
        case this.trxType.value:
          this.trxType = {
            value: null,
            text: null
          };
          break;
        case this.batch_number:
          this.batch_number = '';
          break;
        case this.invoiceStatus:
          this.invoiceStatus = null;
          break;
        case this.payStatus.value:
          this.payStatus = {
            value: null,
            text: null
          };
          break;
      }
    }
  },
  destroyed() {
    clearTimeout(this.setPartyTimeout);
    clearTimeout(this.setTimeVsetCode);
    this.eventBus.$off('getCurrency');
  }
};

// import appStrings from '@/app/utility/string.utility';
export default {
  name: 'codeCombination',
  components: {},
  props: ['combinationDetails'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      segmentMeaning: '',
      filterSegmentCode: null
    };
  },
  mounted() {},
  methods: {
    getConcatSegmentList() {
      const payload = {
        le_id: this.combinationDetails.le_id,
        segment_code: this.filterSegmentCode ? this.filterSegmentCode : this.combinationDetails.project_code,
        segment_meaning: this.segmentMeaning
      };
      this.loader = true;
      this.$store
        .dispatch('leaseRecovery/getFlexFieldAccounting', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.segmentData = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.$emit('selectedSegment', item);
    },
    clearSegmentSearchResult() {
      this.segmentMeaning = '';
      this.filterSegmentCode = null;
      this.getConcatSegmentList();
    }
  }
};
